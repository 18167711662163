/*
:root {
  --green: #409600;
  --success: #409600 !important;
}

.btn-success {
  color: #fff;
  background-color: #409600 !important;
  border-color: #409600 !important;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838 !important;
  border-color: #1e7e34 !important;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838 !important;
  border-color: #1e7e34 !important;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5) !important;
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34 !important;
  border-color: #1c7430 !important;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5) !important;
}
*/

.invoice-items.nav-tabs .nav-link.active {
  border-color: #C0D1E1 #C0D1E1 #fff;
  font-weight: bold;
  color: #6194c4;
  /*color: #2653d4;*/
  /*background-color: #FADF90;*/
  background: linear-gradient(#d4eeffab, #f8f9fc );
  border-radius: 2px 2px 0 0;
}

.invoice-items.nav-tabs .nav-link {
  border-color: #e9ecef #e9ecef #dee2e6;
  
  color: #6194c4;
  /*color: #2653d4;*/
  /*background-color: #FADF90;*/
  /*background: linear-gradient(#f8f9fc, #b8d0fce0);*/
  border-radius: 2px 2px 0 0;
}



.invoice-items.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  background-color: #ddecfc;
}


.invoice-items.nav-tabs {
  border-bottom: 1px solid #C0D1E1;
  border-radius: 2px 2px 0 0;

}

.tab-with-data {
  text-decoration: underline;
}



.list-panel {
  position: absolute;
  z-index: 1;
  padding: 0px;
  background-color: #fff;
  color: #333;
  overflow-y: auto;
  max-height: 400px;
  border: 1px solid darkgreen;
}

.list-panel ul li {
  color: #333;
  padding: 4px 8px;
  margin: 0 !important;
}

.active-item {
  background-color: rgba(74, 176, 215, 0.25);
}

.default-item:hover {
  background-color: rgba(152, 201, 221, 0.25);
}

.uk-list {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}

.integratedButton{
  display: block;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
}  

  
  .insideInput{
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    color:red;
    right: 0;
    
    padding: 4px 4px;
    
  }

.selectedItem {
  background-color: #ddf0dd;
}  

.newCompetitionGroup {
  border-top: 2px solid darkgray !important;
}


.cleaner {
  clear: both;
}

.divide {
  height: 10px;
}

.centerText {
  text-align: center;
}
.rightText {
  text-align: right;
}
.leftText {
  text-align: left;
}
.fullWidth {
  width: 100%;
}
.firmOpenFirstLine {
  text-align: left;
}
.firmOpenSecondLine {
  font-size: 80%;
  text-align: left;
}

.dropdown-toggle.nav-link {
  color: #ffffff;
}
.dropdown-toggle.nav-link:hover {
  color: rgba(255, 255, 255, 0.5);
}

.col-md-8 {
  margin: 0 auto;
}

.alert-no-border-radius {
  border-radius: 0rem !important;
}

.jsw-table-head {
  /*background-color: rgb(66, 163, 223);*/
  background-color: rgb(135, 135, 135);
  color:white;
  font-weight: normal;
}

.row-slim {
  width: 100px;
}

.checkbox-small.form-control.form-control-sm {
  height: calc(1em + .5rem + 2px);
}

.contact-type-header {
  line-height: 3em;
}

.as-link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.as-link-no-color {
  
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.as-link-no-color:hover {
  
  text-decoration: underline;

}

.nodisplay {
  display:none !important;
}

.onlyprint {
  display:none;
}

.nopadding {
  padding-left: 0px !important;
  
}

.nopadding-right {
  padding-right: 0.75rem !important;
  
}

.button-box {
  padding-top: 10px;
  display: inline-block;
}

.own-user span.button-box {
  padding-top: 0px;
}

.table-buttons button.btn {
  margin-bottom: 10px;
}

.card-body:hover {
  background-color: rgba(0,0,0,.05);
}

.card-no-mouse-over-effect {
  background-color: white !important;
}

.border-left-danger.border-left-dotted, .border-left-info.border-left-dotted {
  border-left-style: dotted !important;
}

.button-plus-minus {
  margin-bottom: 3px; 
  padding-left: 5px !important; 
  padding-right: 5px !important;
  width: 25px !important
}

.block-input-with-plus-minus {
  min-width: 121px !important;
}

.actual-module-count {
  width: 50px !important; 
  text-align: center !important;
}

.target-module-count {
  width: 70px !important; 
  text-align: center !important; 
  display: inline-block !important
}

.feedback {
  font-size: 80%;
}

.offer-edit-warning {
  color: red;
  /*font-weight: bold;*/
}

.offer-control {
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input.target-module-count::-webkit-outer-spin-button,
input.target-module-count::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number].target-module-count {
  -moz-appearance: textfield;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;

}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.modal-90w { width: 90% !important; max-width: 1100px !important; }

.sb-sidenav .sb-sidenav-menu .nav .nav-link.showMobileNav {
  display: none;
}
.sb-sidenav-menu-heading.showMobileNav {
  display: none;
}
.showWebNav {
  display: inline-block;
}

.showMobile {
  display: none;
}
.showWeb {
  display: block;
}

.licenseModuleName {
  width: 140px;
}

@media (max-width: 650px) {

  .auth-inner {
    width: auto;
    max-width: 90%;
    
  }

}


@media (max-width: 992px) {

  .sb-sidenav .sb-sidenav-menu .nav .nav-link.showMobileNav {
    display: flex;
  }
  .sb-sidenav-menu-heading.showMobileNav {
    display: flex;
  }
  .showWebNav {
    display: none;
  }
  .showWeb {
    display: none;
  }  
  .showMobile {
    display: block;
  }

}

@media (min-width: 991px) {



}

@media (max-width: 768px) {
  .col.col-open-customer {
    flex-basis: auto;
  }
  .float-right.small-float-left {
    float: left !important;
    clear: both;
  }
  .small-cleaner {
    clear: both;
  }

}

@media (max-width: 820px) {
  .float-right.offer-control {
    text-align: left !important;
    float: left !important
  }
  

}


@media (max-width: 575px) {
  .h1Col {
    text-align: center !important;
    margin-bottom: 40px;
  }
  

}


.login-text-white { color:white;}
.login-text-bold { font-weight: bold;}
.login-container { padding-left: 80px !important; padding-right: 80px !important; font-size: large  ;}

.login-logo-div { text-align: center; margin-bottom: 50px; }
.login-logo { margin-top: -15px; margin-right: 10px; }

.login-text-vertical-center { vertical-align: middle; }

.login,
.image {
  min-height: 100vh;
}

@media (max-width: 768px) {
  .login,
  .image {
    min-height: 70vh;
  }
}


.bg-image {
  background-image: url('/images/bg-e-upg-keyboard.jpg');
  /*background-image: url('https://www.jezeksw.cz/images/design/BlueBackgoundShort.jpg');*/
  background-size: cover;
  background-position: center;
  
  /*opacity: 0.8;*/

}

.login-heading {
  font-weight: 300;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group>input,
.form-label-group>label {
  padding: var(--input-padding-y) var(--input-padding-x);
  height: auto;
  border-radius: 2rem;
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  cursor: text;
  /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown)~label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  padding: 20px;
  width: 1080px;
    margin: 0 auto;
    padding: 0;
    position: relative;
    z-index: 1;
    text-align: left;
    padding-bottom: 0px;
}

.headerObal {
  margin-top: 20px;
}

.header {
  margin-top: 15px;
  width: 1080px;
  margin: 0 auto;
  text-align: left;
  background: #003064;
  position: relative;
  min-height: 60px;
  border-radius: 4px;
}
.header a {
  color: #ffffff;
  font-size: 20px;

  text-decoration: none;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 16px;
  padding-bottom: 16px;

  display: inline;
  
  border-right: 1px solid lightblue;



}
.header a:hover {
  background-color: #c4161c;
}
.header small {
  color: #666;
}
.header .active {
  color: #e0eb50;
}

.headerMenuItem {
  float:left;
}

.headerLogin {
  float:right;
}

div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, nav, section {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

h2 {
  font-size: 35px;
  line-height: 43px;
  padding-top: 20px;
  margin-bottom: 11px;
  color: #333;
}

h3 {
  font-size: 20px;
  line-height: 30px;
  padding-top: 20px;
  margin-bottom: 11px;
  color: #333;
}

.h3 {
  font-size: 20px;
  line-height: 30px;
  padding-top: 20px;
  margin-bottom: 11px;
  color: #333;
  font-weight: bold;
}

.centerText {
  text-align: center;
}

.header a.menuButtonHide {
  display: none;
}
p.crumbtrail {
  background: #e2e3e4;
    margin: 0 0 46px;
    font-size: 13px;
    line-height: 17px;
    padding: 12px 29px 11px;
    color: #777;
    border-radius: 0 0 4px 4px;
    margin-bottom: 47px;
}

p.crumbtrail a {
  color: #777;
  margin-right: 9px;
}

.cart {
  float: right;
}

form {
  border: 1px solid #cacaca;
  padding: 49px 50px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

input[type="text"] {
  font-family: Arial, Helvetica, sans-serif;
  background: #002c5c;
  border: solid 1px #1c5289;
  font-size: 1em;
  padding: 9px;
  margin: 0;
  position: relative;
  background: #f2f2f2;
  color: #333;
  border-color: #cacaca;
  -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    border-radius: 4px;

}

input[type="email"] {
  font-family: Arial, Helvetica, sans-serif;
  background: #002c5c;
  border: solid 1px #1c5289;
  font-size: 1em;
  padding: 9px;
  margin: 0;
  position: relative;
  background: #f2f2f2;
  color: #333;
  border-color: #cacaca;
  -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    border-radius: 4px;

}

input[type="password"] {
  font-family: Arial, Helvetica, sans-serif;
  background: #002c5c;
  border: solid 1px #1c5289;
  font-size: 1em;
  padding: 9px;
  margin: 0;
  position: relative;
  background: #f2f2f2;
  color: #333;
  border-color: #cacaca;
  -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    border-radius: 4px;

}

input[type="number"] {
  font-family: Arial, Helvetica, sans-serif;
  background: #002c5c;
  border: solid 1px #1c5289;
  font-size: 1em;
  padding: 9px;
  margin: 0;
  position: relative;
  background: #f2f2f2;
  color: #333;
  border-color: #cacaca;
  -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    border-radius: 4px;

}

.width100 {
  width: 100px;
}

.width200 {
  width: 200px;
}

.errorMessage {
  color:red;
  font-weight: bold;
}




#data-grid-demo {
  min-height: 700px;
}

/*
input[type="button"] {
  border-radius: 4px;
  line-height: 30px;
}
*/